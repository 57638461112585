@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-datepicker/dist/react-datepicker.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Cela sélectionne la barre de défilement de tous les éléments */
::-webkit-scrollbar {
  width: 5px; /* Largeur de la barre de défilement */
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; /* Fond transparent de la piste de défilement */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aa0000; /* Couleur de la barre de défilement elle-même */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #920000; /* Couleur de la barre de défilement au survol */
}

/* Pour Firefox */
body {
  scrollbar-color: #FF810F;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

/* Classe d'animation */
.fade-in {
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeInComplete {
  from {
    opacity: 0;
  }
  to {
    opacity: 1.0;
  }
}

/* Classe d'animation */
.fade-in-complete {
  animation: fadeInComplete 0.2s forwards;
}

@font-face {
  font-family: 'PermanentMarker Regular';
  src: url('Assets/Fonts/PermanentMarker-Regular.ttf') format('truetype');
}
.PermanentMarker-Regular {
  font-family: 'PermanentMarker Regular', sans-serif;
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: url('Assets/Fonts/Poppins-SemiBold.ttf') format('truetype');
}
.Poppins-SemiBold {
  font-family: 'Poppins SemiBold', sans-serif;
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('Assets/Fonts/Poppins-Bold.ttf') format('truetype');
}
.Poppins-Bold {
  font-family: 'Poppins Bold', sans-serif;
}


@font-face {
  font-family: 'Inter SemiBold';
  src: url('Assets/Fonts/Inter-SemiBold.ttf') format('truetype');
}
.Inter-SemiBold {
  font-family: 'Inter SemiBold', sans-serif;
}

@font-face {
  font-family: 'Inter Bold';
  src: url('Assets/Fonts/Inter-Bold.ttf') format('truetype');
}
.Inter-Bold {
  font-family: 'Inter Bold', sans-serif;
}

@font-face {
  font-family: 'Poppins Regular';
  src: url('Assets/Fonts/Poppins-Regular.ttf') format('truetype');
}
.Poppins-Regular {
  font-family: 'Poppins Regular', sans-serif;
}

@font-face {
  font-family: 'Inter Regular';
  src: url('Assets/Fonts/Inter-Regular.ttf') format('truetype');
}
.Inter-Regular {
  font-family: 'Inter Regular', sans-serif;
}

@keyframes slideIn {
  from {
    width: 0;
    transform: translateX(100%);
  }
  to {
    width: 50%;
    transform: translateX(0);
  }
}

/* Classe d'animation */
.slide-in {
  animation: slideIn 0.5s forwards;
  overflow: hidden; /* Pour s'assurer que le contenu ne déborde pas pendant l'animation */
}

@keyframes slideInComplete {
  from {
    width: 0;
    transform: translateX(100%);
  }
  to {
    width: 100%;
    transform: translateX(0);
  }
}

/* Classe d'animation */
.slide-in-complete {
  animation: slideInComplete 0.2s forwards;
  overflow: hidden; /* Pour s'assurer que le contenu ne déborde pas pendant l'animation */
}

/* Pour les navigateurs WebKit (Chrome, Safari) */
.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Pour Firefox */
.invisible-scrollbar {
  scrollbar-width: none; /* Cache la scrollbar */
}

/* Pour Internet Explorer 10+ et Edge */
.invisible-scrollbar {
  -ms-overflow-style: none; /* Cache la scrollbar */
}

/* Pour les navigateurs WebKit (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 12px; /* Largeur de la scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Fond transparent */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: white; /* Couleur de la scrollbar */
  border-radius: 6px; /* Arrondi des coins */
  border: 3px solid transparent; /* Bord transparent pour créer un espace autour de la scrollbar */
  background-clip: content-box; /* Pour que le fond transparent fonctionne correctement */
}

/* Pour Firefox */
.custom-scrollbar {
  scrollbar-width: thin; /* Scrollbar plus fine */
  scrollbar-color: white transparent; /* Couleur de la scrollbar et du fond */
}

/* Pour Internet Explorer 10+ et Edge */
.custom-scrollbar {
  -ms-overflow-style: -ms-autohiding-scrollbar; /* Scrollbar autohide pour un aspect plus propre */
}
.custom-select {
  @apply text-black;
}

.custom-select option {
  @apply text-black;
}

@font-face {
  font-family: 'Montserrat Regular';
  src: url('Assets/Fonts/Montserrat-Regular.ttf') format('truetype');
}
.Montserrat-Regular {
  font-family: 'Montserrat Regular', sans-serif;
}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: url('Assets/Fonts/Montserrat-SemiBold.ttf') format('truetype');
}
.Montserrat-SemiBold {
  font-family: 'Montserrat SemiBold', sans-serif;
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('Assets/Fonts/Montserrat-Bold.ttf') format('truetype');
}
.Montserrat-Bold {
  font-family: 'Montserrat Bold', sans-serif;
}